import { AfterViewInit, Component } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { LoaderService } from '../services/loader/loader.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-team-gateway',
  standalone: true,
  imports: [],
  templateUrl: './team-gateway.component.html',
  styleUrl: './team-gateway.component.css',
})
export class TeamGatewayComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private authservice: AuthService,
    private loader: LoaderService
  ) {}
  ngAfterViewInit(): void {
    console.log('gateway');
    this.loader.showLoader();
    setTimeout(() => {
      this.authservice.decodeToken().subscribe(async (user) => {
        if (user && user.isMember) {
          this.loader.hideLoader();
          Swal.fire({
            icon: 'success',
            title: 'Parfait ! On vous redirige vers le dashboard.',
            timer: 3000,
            showConfirmButton: false,
          });
          setTimeout(() => {
            window.location.href =
              environment.teamApi +
              '/auth/callback?token=' +
              this.authservice.getToken();
          }, 2000);
        } else {
          this.loader.hideLoader();
          Swal.fire({
            icon: 'info',
            title: 'Connectez pour accéder au dashboard interne de Phonix',
          }).then((res) => {
            if (res.isConfirmed) {
              this.router.navigate(['', 'login'], {
                queryParams: { oauth: true },
              });
            }
          });
        }
      });
    }, 2000);
    // this.authservice.checkAuthentication().subscribe((res) => {
    //   console.log(res);

    //   if (res) {
    //   } else {
    //     this.loader.hideLoader();
    //     // this.router.navigate(['', 'login'], { queryParams: { oauth: true } });
    //   }
    // });
  }
}
