<div style="width: 100%; height: 100%; display: flex; overflow: hidden">
  <div class="background-images">
    <a href="mailto:support@phonixhealth.com">
      <div class="divbuttons">
        <div style="text-align: center">
          <img src="../../assets/mail-icon.webp" alt="mail icon" />
        </div>
        <h2>ENVOYER UN E-MAIL</h2>
      </div>
    </a>
  </div>
  <div class="background-images">
    <a href="https://calendly.com/phonixhealthteam/decouverte_application">
      <div class="divbuttons">
        <div style="text-align: center">
          <img src="../../assets/video-call-icon.webp" alt="visio icon" />
        </div>
        <h2>RÉSERVER UNE VISIO</h2>
      </div>
    </a>
  </div>
</div>
