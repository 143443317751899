import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { catchError, map, of } from 'rxjs';

export const memberGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const User_Service = inject(UserService);
  return User_Service.user_info.pipe(
    map((user) => {
      if (!user.isMember) {
        router.navigate(['']);
        return false;
      }
      return true;
    }),
    catchError(() => {
      router.navigate(['']);
      return of(false);
    })
  );
  return true;
};
