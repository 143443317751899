<div
  style="
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
  "
>
  <button mat-raised-button (click)="redirect('therapy')">Therapy</button>
  <button mat-raised-button (click)="redirect('observation')">
    Observation
  </button>
</div>
