import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contact-us-modal',
    templateUrl: './contact-us-modal.component.html',
    styleUrls: ['./contact-us-modal.component.css'],
    standalone: true,
})
export class ContactUsModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    document.addEventListener('DOMContentLoaded', function () {
      var divsWithBackground = document.querySelectorAll('.background-images');

      divsWithBackground.forEach(function (div: any, index) {
        let image = new Image();
        if (index === 0) {
          image.src = '../../assets/send-email.png';
        } else {
          image.src = '../../assets/video-call.png';
        }

        image.onload = function () {
          console.log('loaded');

          // div.style.backgroundImage = 'url(' + this.src + ')';
          div.style.opacity = '1';
        };
      });
    });
  }
}
