import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { UserService } from '../../user/user.service';
export const SuperAdminGuard = () => {
  const router = inject(Router);
  const User_Service = inject(UserService);
  return User_Service.user_info.pipe(
    map((user) => {
      if (!user.isAdmin) {
        router.navigate(['']);
        return false;
      }
      return true;
    }),
    catchError(() => {
      router.navigate(['']);
      return of(false);
    })
  );
};
