import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-testing-page',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './testing-page.component.html',
  styleUrl: './testing-page.component.css',
})
export class TestingPageComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {}
  redirect(group: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate(['', 'quizfinished', group]);
    }
  }
}
